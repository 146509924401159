export const HOME_ROUTE = "/";
export const SIGN_IN_ROUTE = "/sign-in";
export const SIGN_UP_ROUTE = "/sign-up";
export const CONFIRMATION_ROUTE = "/confirmation";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const BILLING_ROUTE = "/billing";
export const PROFILE_ROUTE = "/profile";
export const PROFILE_ROUTE_UPDATE_USERNAME = "/profile/update-username";
export const PROFILE_ROUTE_UPDATE_USER_AVATAR = "/profile/update-user-avatar";
export const CONTACT_US_ROUTE = "/contact-us";
export const PLANS_ROUTE = "/plans";
